import React, { memo, useEffect, useMemo, useState } from 'react';
import homeIcon from '@/assets/images/homepage/home@2x.png';
import homeRedIcon from '@/assets/images/homepage/home_red@2x.png';
import productsIcon from '@/assets/images/homepage/products@2x.png';
import productsRedIcon from '@/assets/images/homepage/products_red@2x.png';
import ecServiceIcon from '@/assets/images/homepage/ec_service.svg';
import ecServiceDefaultIcon from '@/assets/images/homepage/ec_service_default.svg';
import educationIcon from '@/assets/images/homepage/education@2x.png';
import educationRedIcon from '@/assets/images/homepage/education_red@2x.png';
import myPoliciesIcon from '@/assets/images/homepage/myPolicies@2x.png';
import myPoliciesRedIcon from '@/assets/images/homepage/myPolicies_red@2x.png';
import meIcon from '@/assets/images/homepage/me_icon.png';
import meRedIcon from '@/assets/images/homepage/me_red_icon.png';
import { formatMsg } from '@/locales';
import './index.scss';
import { listen, remove, trigger } from '@/module/event';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { HOMEPAGE_CHANGE_TAB, HOMEPAGE_TAB_CHANGEG } from '@/common/eventKey';
import { PageTab, PageTabValue, TabName, TabValue } from '../types';
import { getUrlParamByKey } from '@/module/url';
// import useTodoTasks from '../useTodoTasks';
import { trackClick } from '@/common/track';
import classNames from 'classnames';
import { isEcHome, isMe } from '../help';
import { getMePageIsFirstValue } from '../../me/helper';
import { GuideConfiguration } from '../../me/components/PageGuide/config';

const meNewIcon = GuideConfiguration.New || '';

interface Tab {
  icon: string;
  selectedIcon: string;
  text: TabValue;
  title?: string;
}
interface IconBadge {
  class_name?: string;
  tab?: string;
  icon?: string;
}

let policiesTitle = '';

policiesTitle = 'My Insurance';




let productTitle = '';



let productIcon = productsIcon;
let productSelectedIcon = productsRedIcon;


export const IS_FIRST_ME_PAGE = '__is_first_in_me_page';

const tabsList: Tab[] = [
  process.env.REGION == 'sg' && isEcHome()
    ? undefined
    : {
        icon: homeIcon,
        selectedIcon: homeRedIcon,
        text: TabName.home,
      },
  
  {
    icon: productIcon,
    selectedIcon: productSelectedIcon,
    text: TabName.products,
    title: productTitle,
  },
  

  
  {
    icon: educationIcon,
    selectedIcon: educationRedIcon,
    text: TabName.education,
  },
  

  {
    icon: isMe ? meIcon : myPoliciesIcon,
    selectedIcon: isMe ? meRedIcon : myPoliciesRedIcon,
    text: isMe ? TabName.me : TabName.policies,
    title: isMe ? '' : policiesTitle,
  },
].filter((v) => Boolean(v)) as Tab[];
interface Props {
  tabFrom: TabValue;
  onChangeTab: Function;
}
function BottomNav(props: Props) {
  const { tabFrom } = props;
  // const { todoTasks } = useTodoTasks(true);
  const [firstInMePage, setFirstInMePage] = useState(false);

  const changeTab = useMemoizedFn((tab: TabValue, params?: any) => {
    if (tab == tabFrom) return;
    trigger(HOMEPAGE_TAB_CHANGEG, tab, params);
    props.onChangeTab(tab);
  });
  const onTabClick = useMemoizedFn((tab: TabValue) => {
    trackClick({
      page_type: 'insurance_home',
      target_type: 'bottom_tab',
      data: {
        type: PageTab[tab],
      },
    });
    changeTab(tab);
  });

  useEffect(() => {
    listen(HOMEPAGE_CHANGE_TAB, changeTab);
    const popstate = function () {
      const t = TabName[getUrlParamByKey('tab') as PageTabValue];
      changeTab(t);
    };
    window.addEventListener('popstate', popstate);
    return () => {
      window.removeEventListener('popstate', popstate);
    };
  }, [changeTab]);

  const getLocal = async (isOver = false) => {
    // 如果缓存有值 则 me icon 右上角不显示 new
    setFirstInMePage(!(await getMePageIsFirstValue()) && !isOver);
  };
  useEffect(() => {
    listen(IS_FIRST_ME_PAGE, getLocal);
    return () => {
      remove(IS_FIRST_ME_PAGE);
    };
  }, [firstInMePage]);

  useEffect(() => {
    getLocal();
  }, []);

  const renderBottomIconBadge = useMemo(
    () =>
      ({ tab }: IconBadge) => {
        const basicPrefix = 'bottom-nav_icon_badge';
        if (tab === TabName.me && firstInMePage) {
          return (
            <div className={`${basicPrefix}_new_wrapper`}>
              <img src={meNewIcon} className={`${basicPrefix}_new`} />
            </div>
          );
        }
      },
    [firstInMePage],
  );
  return (
    <div className="bottom-nav">
      {tabsList.map((item: Tab) => {
        return (
          <div
            key={item.text}
            className={classNames('bottom-nav_item', {
              active: tabFrom == item.text,
            })}
            onClick={() => {
              onTabClick(item.text);
            }}
          >
            <div className="bottom-nav_icon">
              <img
                className="bottom-nav_icon_img"
                src={tabFrom == item.text ? item.selectedIcon : item.icon}
              />
              {renderBottomIconBadge({ tab: item.text })}
              {/* {todoTasks[item.text] ? (
                <div className="bottom-nav_bubble">{todoTasks[item.text]}</div>
              ) : null} */}
            </div>
            <div className="bottom-nav_text">{formatMsg(item.title || item.text)}</div>
          </div>
        );
      })}
    </div>
  );
}

export default memo(BottomNav);
