import { isShopeeMainApp } from '@/common/util';
import { PageTab, TabValue } from '../types';

export const getRightItemConfig = (iconUrl: string) => {
  // 这里读取不到whiteList时默认全量展示, 所以返回true
  if (!isShopeeMainApp || !iconUrl) {
    return;
  }
  return {
    items: [
      {
        type: 'button',
        key: 'chat',
        iconUrl,
      },
    ],
  };
};
export const getTabRightItemConfig = (tab: TabValue, config: any) => {
  const item = config?.find((v: any) => v.tab === tab);
  return item || {};
};

export const TabBarChatTrackConfig: any = {
  [PageTab.Home]: {
    page_section: 'homepage',
    target_type: 'chat_homepage',
  },
  [PageTab.Me]: {
    page_section: 'me_page',
    target_type: 'chats',
  },
};
