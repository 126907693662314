import { parseUrlQuery } from '@/module/url';

export const isEcHome = () => {
  return parseUrlQuery()?.partner_id == '1';
};
// 从 pfc 读取 me 配置
const mePageModuleValue = PFC.getPFC<Record<string, any>>('mePageModule');
export const mePageModule = mePageModuleValue || {};

export const isMe = !!mePageModuleValue && !isEcHome();
