export enum MePageDataEnum {
  MY_FAMILY = 1,
  MY_VEHICLE = 2,
  MY_POLICIES = 3,
  MY_REFERRAL = 4,
  MY_VOUCHERS = 5,
  MY_CLAIMS = 6,
}

export type MePageModule =
  | 'myClaims'
  | 'myPolicies'
  | 'myRewards'
  | 'accountManagement'
  | 'carouselBanner'
  | 'recommendForYou';

export type IMePageModuleMap = {
  [key in MePageModule]: boolean;
};

export interface MePageBaseInfo {
  user_name: string;
  family_count: number;
  vehicle_count: number;
  active_policy_num: number;
  voucher_discount_msg: string;
  referrer_rm_msg: string;
}
export interface PolicyTodoInfo {
  todo_type: string;
  biz_data_number: number;
  discount_msg: string;
  policy_id: string;
  discount_type: string;
}
