import { useEffect } from 'react';
import { listen, remove, trigger } from '@/module/event';
import { HOMEPAGE_CHANGE_TAB, HOMEPAGE_TAB_CHANGEG } from '@/common/eventKey';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { TabValue } from './types';
import { useDidTapBack } from '@/hooks/useDidTapBack';

export type TapBackFn = () => any;

const HOME_TAP_BACK_FN: {
  [K in TabValue]?: TapBackFn;
} = {};

export interface IHomeTabProps {
  onChange?: (tab: TabValue, params?: any) => void;
}

export function changeTab(tab: TabValue, params?: any) {
  trigger(HOMEPAGE_CHANGE_TAB, tab, { changeBy: 'custom', ...params });
}

export function watchTapBack(tab: TabValue, fn: TapBackFn) {
  HOME_TAP_BACK_FN[tab] = fn;
}

export function useTapBack(tab: TabValue) {
  const onBack = useMemoizedFn(() => {
    const fn = HOME_TAP_BACK_FN[tab];
    if (fn) return fn();
  });

  useDidTapBack(onBack);
}

export default function useHomeTab(props?: IHomeTabProps) {
  const { onChange } = props || {};

  useEffect(() => {
    if (!onChange) return;
    listen(HOMEPAGE_TAB_CHANGEG, onChange, { delay: false });
    return () => {
      remove(HOMEPAGE_TAB_CHANGEG, onChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    changeTab,
    useTapBack,
    watchTapBack,
  };
}
