import React, { memo, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { isIOS, isShopeeApp } from '@/common/util';
import { setConfigurePage } from '@/common/biz';
import ChangeableTabBar from '@/components/TabBar';
import { formatMsg, formatValue } from '@/locales';
import { PageTab, TabName, TabValue } from '../types';
import './index.scss';
import { getRightItemConfig, getTabRightItemConfig, TabBarChatTrackConfig } from './help';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { trackClick, trackImpress } from '@/common/track';
import { callNavigateUrl } from '@/bridge';
import { useOnEventCallback } from '@/hooks/useOnEventCallback';
import { queryUnreadChatCount } from '../model';
import { isEcHome } from '../help';

interface IRightItemsConfig {
  items: {
    type: string;
    key: string;
    iconUrl: string;
  }[];
}

export interface ITabBar {
  tabFrom: TabValue;
}
function getTabBarConfig(key: TabValue) {
  let policiesTitle = formatMsg('My Policies');

  
  policiesTitle = formatMsg('My Insurance');
  

  

  let productsTitle = formatMsg('Products');

  

  const TabBarConfig = {
    [TabName.home]: {
      title: formatMsg('Insurance'),
      isTransparent: 1,
    },
    
    [TabName.products]: {
      title: productsTitle,
      isTransparent: 0,
    },
    
    
    [TabName.education]: {
      title: formatMsg('Education'),
      isTransparent: 1,
    },
    
    [TabName.policies]: {
      title: policiesTitle,
      isTransparent: 0,
    },
    
  };
  return TabBarConfig[key];
}
const homeRightItemsConfig: any = PFC.getPFC<Record<string, any>[]>('homeRightItems') || {};
const homeRightItemInfo = homeRightItemsConfig.homeRightItemArray || [];

function FixedTabBar(props: {
  title: string;
  // rightItemsFixedConfig?: IRightItemsConfig;
  tab: TabValue;
  applyRedDot?: boolean;
}) {
  const { title, tab, applyRedDot } = props;
  const titleConfig = useMemo(() => {
    const isMeTitle = title === TabName.me;
    return {
      isMeTitle,
      titleValue: isMeTitle ? '' : title,
    };
  }, [title]);
  // rightitem 不从props 获取
  // 从props取 是异步流程 滞后于title 变化，会闪动
  const rightItemsConfig = useMemo(() => {
    const item = getTabRightItemConfig(tab, homeRightItemInfo);
    if (applyRedDot) {
      return getRightItemConfig(item?.iconUrlOnDarkRedDot || item?.iconUrlOnDark);
    }
    return getRightItemConfig(item?.iconUrlOnDark);
  }, [tab, applyRedDot]);
  useEffect(() => {
    setConfigurePage({
      title: titleConfig.titleValue,
      isTransparent: 1,
      navbarStyle: 0,
      statusBarStyle: 0,
      hideShadow: true,
      rightItemsConfig,
    });
  }, [titleConfig, rightItemsConfig]);

  if (!isShopeeApp) return null;

  return (
    <>
      <div
        className={classNames(
          'tab-bar',
          { ios: isIOS() },
          'tab-bar__holder',
          titleConfig.isMeTitle && 'tab-bar__gray_bg',
        )}
      ></div>
      <div
        className={classNames(
          'tab-bar',
          { ios: isIOS() },
          'tab-bar__affix',
          titleConfig.isMeTitle && 'tab-bar__gray_bg',
        )}
      ></div>
    </>
  );
}

function TabBar(props: ITabBar) {
  const { title, isTransparent } = getTabBarConfig(props.tabFrom);
  const [chatResp, setChatResp] = useState<Record<string, any>>({
    applyRedDot: false,
  });
  const [rightItemsConfig, setRightItemsConfig] = useState<{
    rightItemsFixedConfig?: IRightItemsConfig;
    rightItemsTransConfig?: IRightItemsConfig;
  }>({
    rightItemsFixedConfig: undefined,
    rightItemsTransConfig: undefined,
  });
  const onEventCb = useMemoizedFn(() => {
    // 埋点
    trackClick(TabBarChatTrackConfig[PageTab[props.tabFrom]]);
    const item = getTabRightItemConfig(props.tabFrom, homeRightItemInfo);
    // 点击跳转PFC配置的链接
    const link = formatValue(item?.jumpLink);
    setChatResp({
      applyRedDot: false,
    });
    callNavigateUrl({}, link);
  });

  useOnEventCallback(onEventCb);
  useEffect(() => {
    if (Array.isArray(homeRightItemInfo) && homeRightItemInfo.length) {
      queryUnreadChatCount().then((res) => {
        const applyRedDot = !!res?.unread_count;
        setChatResp({
          applyRedDot,
        });

        const item = getTabRightItemConfig(props.tabFrom, homeRightItemInfo);

        trackImpress(TabBarChatTrackConfig[PageTab[props.tabFrom]]);
        if (applyRedDot) {
          setRightItemsConfig({
            rightItemsTransConfig: getRightItemConfig(item?.iconUrlRedDot || item?.iconUrl),
            rightItemsFixedConfig: getRightItemConfig(
              item?.iconUrlOnDarkRedDot || item?.iconUrlOnDark,
            ),
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const item = getTabRightItemConfig(props.tabFrom, homeRightItemInfo);
    if (chatResp.applyRedDot) {
      setRightItemsConfig({
        rightItemsTransConfig: getRightItemConfig(item?.iconUrlRedDot || item?.iconUrl),
        rightItemsFixedConfig: getRightItemConfig(item?.iconUrlOnDarkRedDot || item?.iconUrlOnDark),
      });
    } else {
      setRightItemsConfig({
        rightItemsTransConfig: getRightItemConfig(item?.iconUrl),
        rightItemsFixedConfig: getRightItemConfig(item?.iconUrlOnDark),
      });
    }
  }, [chatResp, props.tabFrom]);
  // ec home 不展示
  const rightConfig = useMemo(() => {
    return !isEcHome()
      ? {
          rightItemsTransConfig: rightItemsConfig.rightItemsTransConfig,
          rightItemsFixedConfig: rightItemsConfig.rightItemsFixedConfig,
        }
      : {};
  }, [rightItemsConfig]);

  return useMemo(
    () =>
      isTransparent ? (
        <ChangeableTabBar
          title={title}
          fixTitle={true}
          gradualHeight={80}
          rightItemsTransConfig={rightConfig.rightItemsTransConfig}
          rightItemsFixedConfig={rightConfig.rightItemsFixedConfig}
        />
      ) : (
        <FixedTabBar title={title} tab={props.tabFrom} applyRedDot={chatResp?.applyRedDot} />
      ),
    [
      chatResp?.applyRedDot,
      isTransparent,
      props.tabFrom,
      rightConfig.rightItemsFixedConfig,
      rightConfig.rightItemsTransConfig,
      title,
    ],
  );
}

export default memo(TabBar);
