import { IPageGuideConfiguration } from '.';
// 指引文案从 pfc 读取图片地址
export const GuideConfiguration = PFC.getPFC<Record<string, any>>('me_page_guide') || {};

export const getPageGuideConfiguration: (GuideConfiguration: any) => IPageGuideConfiguration[] = (
  config,
) => [
  {
    content: config.AccountText,
    stepBtn: config.Next,
    contentClassName: 'account',
  },
  {
    content: config.PolicyText,
    stepBtn: config.Next,
    contentClassName: 'policy',
  },
  {
    content: config.RewardText,
    stepBtn: config.GotIt,
    contentClassName: 'reward',
  },
];

export const contentTransitionConfig = [
  {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 200,
    },
  },
  {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 200,
    },
  },
  {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: {
      duration: 200,
    },
  },
];

export const textTransitionConfig = [
  {
    from: { x: -100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: { duration: 200 },
  },
  {
    from: { x: 100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: { duration: 200 },
  },
  {
    from: { y: -100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    config: { duration: 200 },
  },
];

export const btnTransitionConfig = [
  {
    from: { x: 100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: { duration: 200 },
  },
  {
    from: { x: -100, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    config: { duration: 200 },
  },
  {
    from: { y: 100, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    config: { duration: 200 },
  },
];
