import { getShopeeUserId } from '@/module/cookie';
import { getLocalItem, setLocalItem } from '@/module/storage';
import { MePageDataEnum } from './types';
import { ERecommendInfoType, EResourceCode } from '@/types/common';
import { RecommendReqDetail } from '@/common/service';
import { trackClick, trackImpress } from '@/common/track';

export const ME_PAGE_GUIDE = 'me_page_guide';

export const MY_ME_PAGE_MODEL = [
  MePageDataEnum.MY_FAMILY,
  MePageDataEnum.MY_POLICIES,
  MePageDataEnum.MY_VEHICLE,
  MePageDataEnum.MY_REFERRAL,
  MePageDataEnum.MY_VOUCHERS,
];
export const getMePageIsFirstKey = () => {
  const userid = getShopeeUserId();
  return `${ME_PAGE_GUIDE}_${userid}`;
};

export const getMePageIsFirstValue = () => {
  return getLocalItem(getMePageIsFirstKey());
};

export const closeMePageIsFirst = () => {
  return setLocalItem(getMePageIsFirstKey(), 1, { expire: '9999d' });
};

export function getRecommendInfoParams() {
  const req_list: RecommendReqDetail[] = [
    {
      resource_code: EResourceCode.SPINMePageBanner,
    },
    {
      resource_code: EResourceCode.SPINMePageProductCard,
    },
  ];
  return {
    req_list,
  };
}

export function getRecommendInfoProps() {
  return {
    [EResourceCode.SPINMePageBanner]: {
      [ERecommendInfoType.ProductCards]: {
        onShow(item: any, index: number) {
          trackImpress({
            page_section: 'banner',
            data: {
              banner_id: index,
              product_id: item.id,
              banner_url: item.redirect_link,
            },
          });
        },
        onClick(item: any, index: number) {
          trackClick({
            page_section: 'banner',
            data: {
              banner_id: index,
              product_id: item.id,
              banner_url: item.redirect_link,
            },
          });
        },
      },
    },
  };
}
