import { unregisterHandler, call } from '@/bridge';
import { useEffect, useRef } from 'react';

// https://confluence.shopee.io/display/SPAT/ConfigurePage

// Generic button. Upon clicked, app will send onEventCallback cmd back to web.
// Web needs to set either iconType, iconUrl or text. If web specifies more than 1, the app will use the above order as priority. This is to allow backwards compatibility.

// 多次注册会多次执行
export function useOnEventCallback(fn: (key: string) => void) {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  useEffect(() => {
    const onEventCallback = call('onEventCallback', async function (key: string) {
      fnRef.current(key);
    });
    return () => {
      unregisterHandler(onEventCallback, 'onEventCallback');
    };
  }, []);
}
