import React, { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import './index.scss';
import classNames from 'classnames';
import { isIOS, isShopeeApp } from '@/common/util';
import useScroll from '@/hooks/useScroll';
import useDomRect from '@/hooks/useDomRect';
import { setConfigurePage as setConfigurePageRaw } from '@/common/biz';
import { listen, remove, trigger } from '@/module/event';
import { KEYBORD_CHANGE, TAB_BAR_GRADUAL_HEIGHT, TAB_BAR_HEIGHT } from '@/common/eventKey';
import useMemoizedFn from '@/hooks/useMemoizeFn';

interface IRightItemsConfig {
  items: {
    type: string;
    key: string;
    iconUrl: string;
  }[];
}
interface ITabBar {
  title?: string;
  noticePosition?: Function;
  gradualHeight?: number; // 渐变高度
  fixTitle?: boolean;
  rightItemsTransConfig?: IRightItemsConfig;
  rightItemsFixedConfig?: IRightItemsConfig;
}
const TabBar = forwardRef((props: ITabBar, comRef) => {
  const [opacity, setOpacity] = useState(0);
  const {
    title,
    noticePosition = () => {},
    gradualHeight = 0,
    fixTitle = false,
    rightItemsTransConfig,
    rightItemsFixedConfig,
  } = props;
  const appHeaderTop = useRef({
    top: gradualHeight,
    showTitle: false,
  });
  const isMounted = useRef(false);
  const keyboardShow = useRef(false);
  const pageOpts = useRef<any>({});

  const setConfigurePage = useMemoizedFn((opts: any) => {
    pageOpts.current = opts;
    if (keyboardShow.current) return;
    setConfigurePageRaw(opts);
  });

  if (!isMounted.current) {
    setConfigurePage({
      title: fixTitle ? title : '',
      isTransparent: 1,
      navbarStyle: 1,
      statusBarStyle: 1,
    });
    isMounted.current = true;
  }

  useEffect(() => {
    const onFocusChange = (isKeyboardShow: boolean) => {
      if (isKeyboardShow) {
        keyboardShow.current = true;
        setConfigurePageRaw({
          title: '',
          hideBackButton: 1,
          rightItemsConfig: {},
        });
        const tabbar = document.getElementById('tab-bar');
        if (tabbar) tabbar.style.display = 'none';
        const navigation = document.getElementById('navigation');
        if (navigation) navigation.style.display = 'none';
      } else {
        keyboardShow.current = false;
        setConfigurePageRaw({
          ...pageOpts.current,
          hideBackButton: 0,
        });
        const tabbar = document.getElementById('tab-bar');
        if (tabbar) tabbar.style.display = '';
        const navigation = document.getElementById('navigation');
        if (navigation) navigation.style.display = '';
      }
    };
    listen(KEYBORD_CHANGE, onFocusChange, { delay: false });
    return () => remove(KEYBORD_CHANGE, onFocusChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConfigurePage({
      title: fixTitle ? title : '',
      isTransparent: 1,
      navbarStyle: 1,
      statusBarStyle: 1,
    });
    const updateGradual = (top: number) => {
      if (top > 0) {
        appHeaderTop.current.top = top;
      }
    };
    listen(TAB_BAR_GRADUAL_HEIGHT, updateGradual);
    return () => {
      remove(TAB_BAR_GRADUAL_HEIGHT, updateGradual);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConfigurePage({
      title: fixTitle ? title : '',
      // 从别的页面回到homepage时 需要重新展示右上角标
      rightItemsConfig: rightItemsTransConfig,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  useEffect(() => {
    if (appHeaderTop.current.showTitle) {
      setConfigurePage({
        ...pageOpts.current,
        rightItemsConfig: rightItemsFixedConfig,
      });
    } else {
      setConfigurePage({
        ...pageOpts.current,
        rightItemsConfig: rightItemsTransConfig,
      });
    }
  }, [rightItemsFixedConfig, rightItemsTransConfig, setConfigurePage]);

  useImperativeHandle(comRef, () => {
    return {
      updateGradualHeight(top: number) {
        appHeaderTop.current.top = top;
      },
    };
  });
  useEffect(() => {
    appHeaderTop.current.top = gradualHeight;
  }, [gradualHeight]);

  useDomRect(
    '#tab-bar',
    (height) => {
      if (appHeaderTop.current.top == 0) {
        appHeaderTop.current.top = height;
      }
      if (isShopeeApp) {
        trigger(TAB_BAR_HEIGHT, height);
        noticePosition(1, height);
      }
    },
    [],
    1000,
  );
  useScroll((scrollTop: number) => {
    let op = scrollTop / (appHeaderTop.current.top || 80);
    if (op > 1) op = 1;
    else if (op < 0.1) op = 0;
    if (op != opacity) {
      setOpacity(op);
    }

    if (op > 0.5 && !appHeaderTop.current.showTitle) {
      appHeaderTop.current.showTitle = true;
      const obj: any = { navbarStyle: 0, statusBarStyle: 0 };
      if (!fixTitle) {
        obj.title = title;
      }
      if (rightItemsFixedConfig) {
        obj.rightItemsConfig = rightItemsFixedConfig;
      }
      setConfigurePage(obj);
    } else if (op < 0.3 && appHeaderTop.current.showTitle) {
      appHeaderTop.current.showTitle = false;
      const obj: any = {
        isTransparent: 1,
        navbarStyle: 1,
        statusBarStyle: 1,
      };
      if (!fixTitle) {
        obj.title = '';
      }
      if (rightItemsTransConfig) {
        obj.rightItemsConfig = rightItemsTransConfig;
      }
      setConfigurePage(obj);
    }
  }, 0);
  if (!isShopeeApp) return null;
  return (
    <div
      className={classNames('tab-bar', {
        ios: isIOS(),
      })}
      id="tab-bar"
      style={{ opacity }}
    ></div>
  );
});

export default memo(TabBar);
