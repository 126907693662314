import React, { Suspense, useLayoutEffect, useState } from 'react';
import BottomNav from './BottomNav';
import './App.scss';
import { PageTab, PageTabValue, ReportPageType, TabName, TabValue } from './types';
import useMemoizedFn from '@/hooks/useMemoizeFn';
import { getUrlParamByKey, pushState, replaceParam } from '@/module/url';
import { LoadingContainer } from '@/components/BasicLoading/loadingElements';
import classNames from 'classnames';
import { trackAutoExpo, trackView } from '@/common/track';
import ErrorInfo from '@/components/ErrorInfo';
import { formatMsg } from '@/locales';
import useHomeTab from './useHomeTab';
import TabBar from './TabBar';
import { EPointerKey, reporter } from '@/common/pointerHandler';
import lazyImport from '@/common/lazyImport';
import { isEcHome, isMe } from './help';
import { setConfigurePage } from '@/common/biz';

let tabValue = getUrlParamByKey('tab') as PageTabValue;
if (!TabName[tabValue]) {
  tabValue = 'home';
}


if (isMe && tabValue == 'policies') {
  tabValue = 'me';
}

const initTab = TabName[tabValue];
trackAutoExpo(ReportPageType[tabValue]);
trackView();

const ComponentsList: {
  key: TabValue;
  visited: boolean;
  Com: React.LazyExoticComponent<React.ComponentType<any>>;
}[] = [
  {
    key: TabName.home,
    visited: initTab == TabName.home,
    Com: lazyImport(() => import('../homePage')),
  },
  {
    key: TabName.products,
    visited: initTab == TabName.products,
    Com: lazyImport(() => import('../productList')),
  },
  {
    key: TabName.education,
    visited: initTab == TabName.education,
    Com: lazyImport(() => import('../education')),
  },
  {
    key: isMe ? TabName.me : TabName.policies,
    visited: initTab == (isMe ? TabName.me : TabName.policies),
    Com: lazyImport(() => {
      if (isMe) {
        return import('../me');
      }
      return import('../myPolices');
    }),
  },
];
const CACHE_PAGE_SCROLL: {
  [K in TabValue]: number;
} = {
  [TabName.home]: 0,
  [TabName.products]: 0,
  [TabName.education]: 0,
  [TabName.policies]: 0,
  [TabName.me]: 0,
};
let isChanging = false;

function App() {
  const [currentTab, setCurrentTab] = useState<`${TabName}`>(initTab);
  const [errInfo, setErrInfo] = useState<{
    [K in TabValue]?: string;
  }>({});

  useLayoutEffect(() => {
    if (isChanging) {
      isChanging = false;
      window.scrollTo(0, CACHE_PAGE_SCROLL[currentTab]);
    }
  });
  const onChangeTab = useMemoizedFn((tab: TabValue) => {
    if (currentTab == tab) return;
    CACHE_PAGE_SCROLL[currentTab] = window.pageYOffset;
    ComponentsList.some((item) => {
      if (item.key == tab) {
        if (!item.visited) {
          reporter(EPointerKey.BEFORE_JS, { origin_time: Date.now() });
        }
        item.visited = true;
        return true;
      }
    });
    isChanging = true;
    pushState(replaceParam('tab', PageTab[tab]));
    errInfo[tab] = '';

    setErrInfo({ ...errInfo });
    // 防止图标闪动
    setConfigurePage({
      rightItemsConfig: {},
    });
    setCurrentTab(tab);
  });
  const retry = useMemoizedFn(() => {
    errInfo[currentTab] = '';

    setErrInfo({ ...errInfo });
  });
  const showError = useMemoizedFn((txt?: string | number) => {
    errInfo[currentTab] = `${txt || formatMsg('common.component.basecomponent.errortext')}`;

    setErrInfo({ ...errInfo });
  });

  const { useTapBack } = useHomeTab();
  useTapBack(currentTab);
  return (
    <div className={classNames('app', `app_${PageTab[currentTab]}`)}>
      <TabBar tabFrom={currentTab} />
      <Suspense fallback={<LoadingContainer cls="app_home-loading"></LoadingContainer>}>
        {ComponentsList.map(({ key, Com, visited }) => (
          <div
            key={key}
            className={classNames('app_item', {
              show: currentTab == key,
            })}
          >
            {visited ? (
              <>
                {errInfo[key] ? (
                  <ErrorInfo retry={retry} errorText={errInfo[key]} />
                ) : (
                  <Com showError={showError} />
                )}
              </>
            ) : null}
          </div>
        ))}
      </Suspense>
      <BottomNav onChangeTab={onChangeTab} tabFrom={currentTab}></BottomNav>
    </div>
  );
}

export default App;
