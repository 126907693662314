export enum TabName {
  home = 'Home',
  products = 'Products',
  education = 'Education',
  policies = 'My Policies',
  me = 'Me',
}
export enum ReportPageType {
  home = 'insurance_home',
  products = 'insurance_product_list',
  education = 'insurance_edu',
  policies = 'insurance_my_policies',
  me = 'insurance_me',
}
export enum PageTab {
  'Home' = 'home',
  'Products' = 'products',
  'Education' = 'education',
  'My Policies' = 'policies',
  'Me' = 'me',
}

export type TabValue = `${TabName}`;
export type PageTabValue = `${PageTab}`;

export interface ComProps {
  showError: (txt?: string | number) => void;
}
